import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import { bindHover } from 'material-ui-popup-state/core'
import { usePopupState } from 'material-ui-popup-state/hooks'
import { useIntl } from 'react-intl'

import { Badge } from '@mui/material'

import CartIcon from 'app/assets/icons/header/cart.svg'
import IconButton from 'app/components/base/IconButton'
import Popper from 'app/components/common/Popover'
import { useGetCartListingsCount } from 'app/services/Query/listing/listing.query'
import useAuthStore, { getIsAuthenticated } from 'app/store/auth.store'

import HeaderButtonContainer from '../layouts/HeaderButtonContainer'

const CartButton = () => {
  const router = useRouter()
  const intl = useIntl()
  const isAuthenticated = useAuthStore(getIsAuthenticated)

  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'cartPopoverId'
  })

  const { data, refetch } = useGetCartListingsCount({
    options: {
      enabled: isAuthenticated
    }
  })

  const hasListings = !!data?.cart_listings_count && isAuthenticated

  useEffect(() => {
    if (isAuthenticated) {
      refetch()
    }
  }, [router.asPath])

  return (
    <HeaderButtonContainer>
      <IconButton
        aria-label="Cart"
        data-testid="cart-button"
        onClick={() => router.push('/cart')}
        {...bindHover(popupState)}
      >
        <Badge
          variant="dot"
          invisible={!hasListings}
          color="secondary"
          sx={{
            '.MuiBadge-badge': {
              top: 3,
              right: 3,
              minWidth: 'auto',
              width: 14,
              height: 14,
              border: '1px solid',
              borderColor: 'background.default',
              borderRadius: '50%'
            }
          }}
        >
          <CartIcon />
        </Badge>
      </IconButton>

      {popupState.isOpen && (
        <Popper
          placement="bottom"
          text={intl.formatMessage({ defaultMessage: 'Cart' })}
          popupState={popupState}
        />
      )}
    </HeaderButtonContainer>
  )
}

export default CartButton

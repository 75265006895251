export const listingKeys = {
  listing: ['listing'] as const,
  getListing: (slug) => [...listingKeys.listing, 'getListing', slug],
  getListingsByUser: (params) => [
    ...listingKeys.listing,
    'getListingsByUser',
    params
  ],
  getListingsPriceRang: () => [...listingKeys.listing, 'getListingsPriceRang'],
  getUserListing: (slug) => [...listingKeys.listing, 'getUserListing', slug],
  getUserListings: (params) => [
    ...listingKeys.listing,
    'getUserListings',
    params
  ],
  createListing: () => [...listingKeys.listing, 'createListing'],
  createDraftListing: () => [...listingKeys.listing, 'createDraftListing'],
  updateListing: () => [...listingKeys.listing, 'updateListing'],
  updateDraftListing: () => [...listingKeys.listing, 'updateDraftListing'],
  deleteListing: () => [...listingKeys.listing, 'deleteListing'],
  deactivateListing: () => [...listingKeys.listing, 'deactivateListing'],
  duplicateListing: () => [...listingKeys.listing, 'duplicateListing'],
  deleteDraftListing: () => [...listingKeys.listing, 'deleteDraftListing'],
  publishListing: () => [...listingKeys.listing, 'publishListing'],
  publishAllListings: () => [...listingKeys.listing, 'publishAllListings'],
  getViewedListings: (params) => [
    ...listingKeys.listing,
    'getViewedListings',
    params
  ],
  editPublishedListing: () => [...listingKeys.listing, 'editPublishedListing'],
  getAwsConfigData: () => [...listingKeys.listing, 'getAwsConfigData'],
  uploadToAsw: () => [...listingKeys.listing, 'uploadToAsw'],
  bulkUpload: () => [...listingKeys.listing, 'bulkUpload'],
  getLikedListings: (params) => [
    ...listingKeys.listing,
    'getLikedListings',
    params
  ],
  createLikedListing: () => [...listingKeys.listing, 'createLikedListing'],
  deleteLikedListing: () => [...listingKeys.listing, 'deleteLikedListing'],
  getVehicleLikedAvailableListings: (vehicleId) => [
    ...listingKeys.listing,
    'getVehicleLikedAvailableListings',
    vehicleId
  ],
  getLikedListingsBySlug: (listingSlugs) => [
    ...listingKeys.listing,
    'getLikedListingsBySlug',
    listingSlugs
  ],
  getVinNumber: (params) => [...listingKeys.listing, 'getVinNumber', params],
  getCartListings: (params) => [
    ...listingKeys.listing,
    'getCartListings',
    params
  ],
  addListingToCart: () => [...listingKeys.listing, 'addListingToCart'],
  getCartTotalPrice: () => [...listingKeys.listing, 'getCartTotalPrice'],
  updateCartListing: () => [...listingKeys.listing, 'updateCartListing'],
  deleteListingFromCart: () => [
    ...listingKeys.listing,
    'deleteListingFromCart'
  ],
  getCartListingsCount: () => [...listingKeys.listing, 'getCartListingsCount'],
  validateCartListings: () => [...listingKeys.listing, 'validateCartListings'],
  generateListingLabel: () => [...listingKeys.listing, 'generateListingLabel'],
  updateBlockedListing: () => [...listingKeys.listing, 'updateBlockedListings'],
  updateTradePriceAllListings: () => [
    ...listingKeys.listing,
    'updateTradePriceAllListings'
  ],
  deactivateTradePriceAllListings: () => [
    ...listingKeys.listing,
    'deactivateTradePriceAllListings'
  ],
  getTradePriceAllListings: () => [
    ...listingKeys.listing,
    'getTradePriceAllListings'
  ],
  getSimilarListings: (listingId: string) => [
    ...listingKeys.listing,
    'getSimilarListings',
    listingId
  ]
}
